/*
 * @Author: 龙雨欢 932838577@qq.com
 * @Date: 2022-10-17 14:58:38
 * @LastEditors: 龙雨欢 932838577@qq.com
 * @LastEditTime: 2024-03-22 17:03:45
 * @FilePath: \sxyd-pub-cli5\src\main.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import  zhCn from "element-plus/lib/locale/lang/zh-cn";
import 'element-plus/dist/index.css'
import "lib-flexible-computer";
createApp(App).use(router).use(ElementPlus, { size: 'small',locale: zhCn }).mount('#app')
