/*
 * @Author: 龙雨欢 932838577@qq.com
 * @Date: 2022-10-17 14:58:38
 * @LastEditors: 龙雨欢 932838577@qq.com
 * @LastEditTime: 2024-03-25 10:51:37
 * @FilePath: \sxyd-pub-cli5\src\router\index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/homepage',
    name: '/',
    meta: {title:'兵团三线一单公众版平台'}
  },
  {
    path: '/homepage',
    name: 'homepage',
    component: () => import('../views/HomepageView.vue'),
    meta: {title:'兵团三线一单公众版平台'},
  },
  {
    path: '/home',
    name: 'home',
    meta: {title:'兵团三线一单公众版平台'},
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/scenario',
    name: 'scenario',
    meta: {title:'兵团三线一单公众版平台'},
    component: () => import('../views/scenario.vue')
  },
  {
    path: '/policy',
    name: 'policy',
    meta: {title:'兵团三线一单公众版平台'},
    component: () => import('../views/PolicyView2.vue')
  },
  {
    path: '/pollutant',
    name: 'pollutant',
    meta: {title:'兵团三线一单公众版平台'},
    component: () => import('../views/pollutant.vue')
  },
  {
    path: '/enterprises',
    name: 'enterprises',
    meta: {title:'兵团三线一单公众版平台'},
    component: () => import('../views/enterprises.vue')
  },
  {
    path: '/grading',
    name: 'grading',
    meta: {title:'兵团三线一单公众版平台'},
    component: () => import('../views/grading.vue')
  },
  {
    path: '/jiance',
    name: 'jiance',
    component: () => import('../views/jiance.vue')
  },
  {
    path: '/paiwuHome',
    name: 'paiwuHome',
    component: () => import('../views/paiwuHome.vue')
  },
  {
    path: '/paiwu',
    name: 'paiwu',
    component: () => import('../views/paiwu.vue')
  },
  {
    path: '/paiwu2',
    name: 'paiwu2',
    component: () => import('../views/paiwu2.vue')
  },
  {
    path: '/xinyongHome',
    name: 'xinyongHome',
    component: () => import('../views/xinyongHome.vue')
  },
  {
    path: '/system',
    name: 'system',
    meta: {title:'兵团三线一单公众版平台'},
    component: () => import('../views/SystemView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
